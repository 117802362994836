import PortalModal from 'app/core/components/modals/common/PortalModal';
import { MouseEvent, useContext, useRef } from 'react';
import { AchievementsBalanceWidget } from '../AchievementsBalanceWidget';
import { AchievementsList } from '../AchievementsList';
import { UserAchievementsList } from '../UserAchievementsList';
import { AchievementsMerchList } from '../AchievementsMerchList';
import { CurrentUserContext } from 'app/core/components/CurrentUserContext';
import { useQuery } from '@blitzjs/rpc';
import getUserAchievements from 'app/users/queries/getUserAchievements';

interface AchievementsModalProps {
    trigger?: (onOpen: () => void) => JSX.Element;
}

export const AchievementsModal = ({ trigger }: AchievementsModalProps) => {
    const user = useContext(CurrentUserContext);

    const achievementsElRef = useRef<HTMLDivElement | null>(null);

    const [achievements = []] = useQuery(getUserAchievements, {}, { refetchOnMount: true });

    const scrollToAchievements = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        achievementsElRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <PortalModal
            className="achievements-modal"
            opener={trigger}
            overlayClassName="achievements-modal__overlay"
            title="Collect emoticons and exchange them for cool prizes from GitNation"
        >
            <div className="achievements-view">
                <div className="achievements-view__balance">
                    <AchievementsBalanceWidget />
                </div>

                <div className="achievements-view__merch">
                    <AchievementsMerchList />
                </div>

                {user && achievements.length > 0 && (
                    <div className="achievements-view__user-achievements">
                        <h3>
                            Your achievements{' '}
                            <a className="visible-lg" onClick={scrollToAchievements}>
                                How get emoticons
                            </a>
                        </h3>

                        <UserAchievementsList />
                    </div>
                )}

                <div ref={achievementsElRef} className="achievements-view__achievements">
                    <h3>How can you get emoticons:</h3>

                    <AchievementsList />
                </div>
            </div>
        </PortalModal>
    );
};
