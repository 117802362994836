import CupMerchIcon from '../../icons/merch/cup-merch-icon.svg';
import KeychainMerchIcon from '../../icons/merch/keychain-merch-icon.svg';
import CapMerchIcon from '../../icons/merch/cap-merch-icon.svg';
import HoodiesMerchIcon from '../../icons/merch/hoodies-merch-icon.svg';
import TShirtMerchIcon from '../../icons/merch/t-shirt-merch-icon.svg';
import MultipassYearMerchIcon from '../../icons/merch/multipass-year-merch-icon.svg';
import MultipassLifetimeMerchIcon from '../../icons/merch/multipass-lifetime-merch-icon.svg';
import CitizenshipMerchIcon from '../../icons/merch/citizenship-merch-icon.svg';
import FullRemoteTicketMerchIcon from '../../icons/merch/full-remote-ticket-merch-icon.svg';
import IrlTicketMerchIcon from '../../icons/merch/irl-ticket-merch-icon.svg';
import TourRemoteTicketMerchIcon from '../../icons/merch/tour-ticket-merch-icon.svg';

export const MERCH_LIST_DATA = [
    {
        id: 0,
        name: 'Cup',
        iconLink: CupMerchIcon,
        price: 80,
    },
    {
        id: 1,
        name: 'Keychain',
        iconLink: KeychainMerchIcon,
        price: 80,
    },
    {
        id: 2,
        name: 'Cap',
        iconLink: CapMerchIcon,
        price: 80,
    },
    {
        id: 3,
        name: 'Full remote ticket',
        iconLink: FullRemoteTicketMerchIcon,
        price: 100,
    },
    {
        id: 4,
        name: 'Hoodie',
        iconLink: HoodiesMerchIcon,
        price: 200,
    },
    {
        id: 5,
        name: 'T-shirt',
        iconLink: TShirtMerchIcon,
        price: 200,
    },
    {
        id: 6,
        name: 'Multipass for a year',
        iconLink: MultipassYearMerchIcon,
        price: 200,
    },
    {
        id: 7,
        name: 'IRL ticket',
        iconLink: IrlTicketMerchIcon,
        price: 500,
    },
    {
        id: 8,
        name: 'Lifetime Multipass',
        iconLink: MultipassLifetimeMerchIcon,
        price: 700,
    },
    {
        id: 9,
        name: 'All-paid tour to a conference',
        iconLink: TourRemoteTicketMerchIcon,
        price: 3000,
    },
    {
        id: 10,
        name: 'Citizenship',
        iconLink: CitizenshipMerchIcon,
        price: 5000,
        buttonLabel: 'Apply',
    },
];
